import React from "react";

const URLCard = (props) => {
    const sharedURL = props.sharedURL;

    const renderPastURLObjects = () => {
        if (sharedURL !== null) {
            return (
                <div className="shared-url-card">
                    <div className="past-url-card-table">
                        {/* <div className="table-row">
                            <p className="table-key">作成日時</p>
                            <p className="table-value">{sharedURL.created_at}</p>
                        </div> */}
                        <div className="table-row">
                            <p className="table-key">共有された情報</p>
                            <a className="table-value origin-url" href={sharedURL.origin_url} target="_blank" rel="noopener noreferrer"
                                // TODO: onClick={()=>{}} 
                            >{sharedURL.origin_url}</a>
                        </div>
                        <div className="table-row">
                            <p className="table-key">タイトル</p>
                            <p className="table-value">{sharedURL.title}</p>
                        </div>
                        {/* <div className="table-row">
                            <p className="table-key">共有URL</p>
                            <p className="table-value">{sharedURL.share_url}</p>
                        </div> */}
                        <div className="table-row">
                            <p className="table-key">作成した人</p>
                            <p className="table-value">{sharedURL.created_user}</p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div>
            <h2>共有コンテンツ</h2>
            {renderPastURLObjects()}
        </div>
    )

}

export default URLCard;