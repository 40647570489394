import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../img/logo_oudan_sharing.png";

const Header = (props) => {
    const [ styleHome, setStyleHome ] = useState({ color: "#002e5c" });
    const [ styleHistory, setStyleHistory ] = useState({ color: "#002e5c" });
    const [ styleProfile, setStyleProfile ] = useState({ color: "#002e5c" });
    const pathname = window.location.pathname;
    const pathHome = "/";
    const pathHistory = "/history";
    const pathProfile = "/profile";
    const auth_flag = localStorage.getItem('auth_flag');

    useEffect(() => {
        if (pathname === pathHome) {
            setStyleHome({ color: "#00bcd4" });
        } 
        else if (pathname === pathHistory) {
            setStyleHistory({ color: "#00bcd4" });
        } 
        else if (pathname === pathProfile) {
            setStyleProfile({ color: "#00bcd4" });
        }
    }, [pathname]);

    const renderHeaderIcons = () => {
        if (auth_flag === "true") {
            return (
                <div className="header-icons">
                    <Link to={pathHome}>
                        <FontAwesomeIcon className="header-icon"
                            icon="fa-solid fa-home" size="2x" style={styleHome} />
                    </Link>
                    <Link to={pathHistory}>
                        <FontAwesomeIcon className="header-icon"
                            icon="fa-solid fa-clock-rotate-left" size="2x" style={styleHistory} />
                    </Link>
                    <Link to={pathProfile}>
                        <FontAwesomeIcon className="header-icon"
                            icon="fa-solid fa-user" size="2x" style={styleProfile} />
                    </Link>
                </div>
            )
        } else if (auth_flag === "false") {
            if (pathname !== '/login') {
                return (
                    <div className="header-icons">
                        <Link to="/login" className="login-link">
                            ログイン
                        </Link>
                    </div>
                )
            }
        }
    };

    return (
        <header className="header">
            <img className="header-logo" src={Logo} alt="Oudan sharing logo" width="15%"/>
            {renderHeaderIcons()}
        </header>
    )
};

export default withRouter(Header);