import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Amplify, Auth } from 'aws-amplify';
import axios from "axios";
import { Helmet } from "react-helmet"

import Header from "./Header";
import Footer from "./Footer";
import URLCard from "./SharedURL/URLCard";
import Signature from "./SharedURL/Signature";
import CookieConsentBanner from "./Cookie/CookieConsentBanner";

import ConfigAuth from "./Auth/Auth";

Amplify.configure({ Auth: ConfigAuth });
const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });
const org_name = window.location.host.split('.')[0];
const shared_id = window.location.pathname.split('/')[2];

const SharedURL = (props) => {
    const [ sharedURL, setSharedURL ] = useState(null);
    const [ user, setUser ] = useState(null);
    const [ title, setTitle ] = useState(null);

    const getSharedURL = async () => {
        await API.get(`/shared_url/${shared_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        }).then((res) => {
                setSharedURL(res.data.contents);
                let title = res.data.contents.title
                let created_user = res.data.contents.created_user
                document.title = `${title} - ${created_user} | ${org_name} | Oudan Sharing`;
                setTitle(`${title} - ${created_user} | ${org_name} | Oudan Sharing`);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const checkAuth = async (jwtToken) => {
        await API.post('/api/check_auth', { 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`,
        }).then((res) => {
                localStorage.setItem('auth_flag', res.data.result);
            }).catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(async (res) => {
                setUser({
                    username: res.username,
                    email: res.attributes.email,
                });
                if (localStorage.getItem('auth_flag') !== 'true') {
                    await checkAuth(res['signInUserSession']['idToken']['jwtToken']);
                }
            })
            .catch(() => {
                localStorage.setItem('auth_flag', false);
            });
        getSharedURL();
    }, [props]);

    const renderSignatureCard = () => {
        const user_name = user ? user.username : null;
        const created_user = sharedURL ? sharedURL.created_user : null;
        if (user_name !== created_user) {
            return (
                <Signature
                    org_name={org_name}
                    user_name={user_name}
                    shared_id={shared_id}
                />
            )
        }
    }

    const renderHelmet = () => {
        const created_user = sharedURL ? sharedURL.created_user : null;
        return (
            <Helmet>
                <title>{title}</title>
                <meta charset="utf-8" />
                <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#b8b9be" />
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap"/>
                <meta property="og:url" content="https://oudan-sharing.com/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:site_name" content={title} />
                <meta property="og:locale" content="ja_JP" />
                {/* <meta property="og:description" content={`${created_user} によって作成されました。`} /> */}
            </Helmet>
        )
    }

    return (
        <div className="main">
            {renderHelmet()}
            <Header />
            <div className="contents">
                <URLCard
                    sharedURL={sharedURL}
                />
                {renderSignatureCard()}
            </div>
            <CookieConsentBanner />
            <Footer />
        </div>
    )

}

export default withRouter(SharedURL);