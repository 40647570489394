import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Amplify, Auth } from 'aws-amplify';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Header from "./Header";
import Footer from "./Footer";
import InputURL from "./Home/InputURL";
// import ShareURL from "./Home/ShareURL";
import CookieConsentBanner from "./Cookie/CookieConsentBanner";

import ConfigAuth from "./Auth/Auth";

Amplify.configure({ Auth: ConfigAuth });
const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });
const org_name = window.location.host.split('.')[0];

const date = new Date();
date.setDate(date.getDate() + 1);
const tomorrow = date.toLocaleString("sv-SE", { timeZone: "Asia/Tokyo" }).slice(0, 16).replace(/\s+/g, "T"); // Format: yyyy-MM-ddThh:mm

const Home = (props) => {
    const [ shareURLCardState, setShareURLCardState ] = useState(false);
    const [ shareURL, setShareURL ] = useState(null);
    const [ shareURLTitle, setShareURLTitle ] = useState(null);
    const [ shareURLLimit, setShareURLLimit ] = useState(tomorrow);
    const [ shareURLTarget, setShareURLTarget ] = useState([]);
    const [ targetCantidateUsers, setTargetCantidateUsers ] = useState([]);
    const [ targetCantidateGroups, setTargetCantidateGroups ] = useState([]);
    const [ createdURL, setCreatedURL ] = useState(null);
    const [ user, setUser ] = useState(null);

    const checkAuth = async (jwtToken) => {
        await API.post('/api/check_auth', { 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`,
        }).then((res) => {
            localStorage.setItem('auth_flag', res.data.result);
        }).catch((err) => {
            console.log(err);
        });
    };

    const getTargetCantidates = async (user_name) => {
        if (user_name !== null && org_name !== null) {
            await API.post('/browsing_target/listup', {
                'Content-Type': 'application/json',
                "org_name": org_name,
                "user_name": user_name,
            }).then((res) => {
                setTargetCantidateUsers(res.data.users);
                setTargetCantidateGroups(res.data.groups);
                // res.data.groups.map((group) => {
                //     if (group.name === '全員') {
                //         setShareURLTarget([group]);
                //     }
                //     return null;
                // }, )
            }).catch((err) => {
                console.log(err);
            });
        }
    };

    useEffect(() => {
        document.title = `ホーム | ${org_name} | Oudan Sharing`;
        Auth.currentAuthenticatedUser()
            .then(async (res) => {
                setUser({
                    username: res.username,
                    email: res.attributes.email,
                });
                if (localStorage.getItem('auth_flag') !== 'true') {
                    let jwtToken = res['signInUserSession']['idToken']['jwtToken']
                    await checkAuth(jwtToken);
                    await getTargetCantidates(res.username);
                } else if (localStorage.getItem('auth_flag') === 'true') {
                    await getTargetCantidates(res.username);
                }
            })
            .catch(() => {
                localStorage.setItem('auth_flag', false);
                props.history.push("/login");
            });
    }, [props]);

    const hundleCreateURL = async (url, title, limit, target) => {
        await API.post('/share_url/create', {
            'Content-Type': 'application/json',
            'org_name': org_name,
            'user_name': user.username,
            'origin_url': url,
            'title': title,
            'target': target,
            'limit': limit,
        }).then((res) => {
            let created_url_obj = res.data.contents;
            setCreatedURL(created_url_obj['share_url']);
            setShareURLCardState(true);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleClickCreateURL = (e) => {
        e.preventDefault();
        if (shareURL !== null && shareURLTitle !== null) {
            hundleCreateURL(shareURL, shareURLTitle, shareURLLimit, shareURLTarget);
        }
    };

    const renderSendMessage = () => {
        return (
            <div className="copied-popup">
                <FontAwesomeIcon icon="fas fa-check-circle" />
                <p className="copied-text">指定したメンバーに共有しました！</p>
            </div>
        )
    };

    return (
        <div className="main">
            <Header />
            <div className="contents">
                <InputURL
                    handleClickCreateURL={handleClickCreateURL}
                    shareURL={shareURL}
                    setShareURL={setShareURL}
                    shareURLTitle={shareURLTitle}
                    setShareURLTitle={setShareURLTitle}
                    shareURLLimit={shareURLLimit}
                    setShareURLLimit={setShareURLLimit}
                    shareURLTarget={shareURLTarget}
                    setShareURLTarget={setShareURLTarget}
                    targetCantidateUsers={targetCantidateUsers}
                    targetCantidateGroups={targetCantidateGroups}
                />
                {shareURLCardState ?
                    renderSendMessage()
                : null}
            </div>
            <CookieConsentBanner />
            <Footer />
        </div>
    );
};

export default withRouter(Home);
