import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const Signature = (props) => {
    const org_name = props.org_name;
    const user_name = props.user_name;
    const shared_id = props.shared_id;
    const [ cookies, setCookie ] = useCookies(["signature"]);
    const [ signature, setSignature] = useState("");
    const [ submited, setSubmited] = useState(false);
    const [ submitedDisplay, setSubmitedDisplay] = useState(false);
    const [ isValidate, setIsValidate] = useState(false);

    useEffect(() => {
        if (cookies.signature !== undefined) {
            setSignature(cookies.signature);
            if (cookies.signature.length > 0) {
                setIsValidate(true);
            };
        };
        if (cookies[`signatured-${shared_id}`] === undefined) {
            setCookie(`signatured-${shared_id}`, false);
        };
    }, [cookies, shared_id, setCookie]);

    const handleOnChange = (value) => {
        setSignature(value);

        let valid_length = value.length > 0;
        let valid_string = !(value.includes(',') || value.includes(' ') || value.includes('　'))
        console.log()
        if (valid_length && valid_string) {
            setIsValidate(true);
        } else {
            setIsValidate(false);
        }
    };

    const hundlePostSignature = async () => {
        let auth_flag = localStorage.getItem('auth_flag')

        if (auth_flag === 'false') {
            await API.post('/signature/external', { 
                'Content-Type': 'application/json',
                'share_id': shared_id,
                'signature_text': signature,
            }).then(() => {
                    setCookie(`signatured-${shared_id}`, true);
                    setSubmited(true);
                    setSubmitedDisplay(true);
                    setTimeout(() => {
                        setSubmitedDisplay(false);
                    }, 1000);
                }).catch((err) => {
                    console.log(err);
                });
        } else {
            await API.post('/signature/internal', { 
                'Content-Type': 'application/json',
                'org_name': org_name,
                'user_name': user_name,
                'share_id': shared_id,
                'signature_text': signature,
            }).then(() => {
                    setCookie(`signatured-${shared_id}`, true);
                    setSubmited(true);
                    setSubmitedDisplay(true);
                    // setTimeout(() => {
                    //     setSubmitedDisplay(false);
                    // }, 1000);
                }).catch((err) => {
                    console.log(err);
                });
        }
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        hundlePostSignature();
        setCookie("signature", signature);
    }

    const renderSubmitButton = () => {
        if (signature !== "" && cookies[`signatured-${shared_id}`] === "false" && submited === false && isValidate) {
            return <button className="button" type="submit" onClick={handleOnSubmit} >送信</button>
        } else if (signature !== "" && cookies[`signatured-${shared_id}`] === "true" && submited === false && isValidate){
            return <button className="button" type="submit" onClick={handleOnSubmit} >送信</button>
        } else if (signature !== "" && cookies[`signatured-${shared_id}`] === undefined && submited === false && isValidate){
            return <button className="button" type="submit" onClick={handleOnSubmit} >送信</button>
        } else {
            return <button className="button disabled-button" type="submit" onClick={handleOnSubmit} disabled>送信</button>
        }
    }

    const renderPastURLObjects = () => {
        return (
            <form className="past-url-card-table">
                <div className="table-row-profile">
                    <div className="table-row-profile-content">
                        <input className="input-signature" type="text" placeholder="名前かメールアドレスを記入"
                            defaultValue={cookies.signature}
                            onChange={(e)=>{handleOnChange(e.target.value)}} />
                    </div>
                </div>
                {submitedDisplay ?
                    <div className="copied-popup past-url-card-table-submited">
                        <FontAwesomeIcon icon="fas fa-check-circle" />
                        <p className="copied-text">送信しました！</p>
                    </div>
                : null}
                {renderSubmitButton()}
            </form>
        )
    }

    return (
        <div className="signature-card">
            <h2>署名</h2>
            <p className="description">
                署名して URL を閲覧したことを共有しましょう。<br />
                空白(半角、全角)やカンマ(,)は使用できません。
            </p>
            {renderPastURLObjects()}
        </div>
    )

}

export default Signature;