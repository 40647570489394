import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const date = new Date();
date.setDate(date.getDate() + 1);
const tomorrow = date.toLocaleString("sv-SE", { timeZone: "Asia/Tokyo" }).slice(0, 16).replace(/\s+/g, "T"); // Format: yyyy-MM-ddThh:mm

const InputURL = (props) => {
    const handleClickCreateURL = props.handleClickCreateURL;
    const shareURL = props.shareURL;
    const setShareURL = props.setShareURL;
    const shareURLTitle = props.shareURLTitle;
    const setShareURLTitle = props.setShareURLTitle;
    const shareURLLimit = props.shareURLLimit;
    const setShareURLLimit = props.setShareURLLimit;
    const shareURLTarget = props.shareURLTarget;
    const setShareURLTarget = props.setShareURLTarget;
    const targetCantidateUsers = props.targetCantidateUsers;
    const targetCantidateGroups = props.targetCantidateGroups;
    const [ validURL, setValidURL ] = useState(false);
    const [ isSubmit, setIsSubmit ] = useState(false);
    // const [ isShowTargetSelects, setIsShowTargetSelects ] = useState(false);

    const hundleSubmit = (e) => {
        e.preventDefault();
        setIsSubmit(true);
        handleClickCreateURL(e);
    };

    const renderButton = () => {
        if (validURL && (shareURL !== null && shareURLTitle !== null && shareURLTarget.length !== 0 && shareURLLimit !== null)) {
            if (isSubmit) {
                return <button className="button disabled-button" type="submit" disabled>指定したメンバーに送信</button>;
            } else {
                return <button className="button" type="submit" onClick={(e) => {hundleSubmit(e)}}>指定したメンバーに送信</button>;
            }
        } else {
            return <button className="button disabled-button" type="submit" disabled>指定したメンバーに送信</button>;
        }
    };

    const renderWaringMessage = () => {
        if (!validURL && (shareURL !== null && shareURLTitle !== null)) {
            return (
                <div className="url-valid-popup">
                    <FontAwesomeIcon icon="fas fa-times-circle" />
                    <p className="url-valid-text">入力された URL は形式が正しくありません！</p>
                </div>
            )
        }
    };

    const renderCantidates = (items) => {
        let targetList = shareURLTarget;
        let targetListIDs = targetList.map((target) => {
            return target.id;
        });
        return (
            <div className="mention-area">
                {
                    items.map((item, i) => {
                        if (targetListIDs.includes(item.id)) {
                            return (
                                <label className="checkbox-label" id={i} key={i}>
                                    <input className="checkbox" name="target-candidate" type="checkbox" id={i} key={i} value={item.name}
                                        onClick={()=>{
                                            let newTargetList = targetList.filter((target) => {
                                                return target.id !== item.id;
                                            });
                                            setShareURLTarget(newTargetList);
                                            return;
                                        }} defaultChecked />
                                    <span>@{item.name}</span>
                                </label>
                            )
                        } else {
                            return (
                                <label className="checkbox-label"  id={i} key={i}>
                                    <input className="checkbox" name="target-candidate" type="checkbox" id={i} key={i} value={item.name}
                                        onClick={()=>{
                                            let newTargetList = targetList.filter((target) => {
                                                return target.id !== item.id;
                                            });
                                            newTargetList.push(item);
                                            setShareURLTarget(newTargetList);
                                            return;
                                        }} />
                                    <span>@{item.name}</span>
                                </label>
                            )
                        }
                    })
                }
            </div>
        )
    };

    const renderTargetSelects = () => {
        // if (isShowTargetSelects) {
        return (
            <div className="target-selects">
                {/* <div className="target-selects-button" onClick={(_) => {setIsShowTargetSelects(!isShowTargetSelects)}}>
                    <FontAwesomeIcon icon="fa-solid fa-circle-minus"/>
                    <span>閲覧期限と通知先をリセット</span>
                </div> */}
                <div className="target-description">
                    <p className="target-description-subtitle">閲覧期限を設定</p>
                    {/* <span>デフォルトで１日後を期限に指定しています</span> */}
                </div>
                <input className="textarea timelimit-area" type="datetime-local" name="datetime" defaultValue={tomorrow}
                    onChange={(e)=>{setShareURLLimit(e.target.value)}} />
                <div className="target-description">
                    <p className="target-description-subtitle">共有したいターゲットを設定</p>
                    {/* <br />
                    <span>デフォルトで「全員」を対象に指定しています</span>
                    <br />
                    <span>特定の対象を選択したいときは「全員」を外してください</span> */}
                </div>
                <div className="mention-wrapper">
                    <p>チームから選ぶ</p>
                    {renderCantidates(targetCantidateGroups)}
                </div>
                <div className="mention-wrapper">
                    <p>メンバーから選ぶ</p>
                    {renderCantidates(targetCantidateUsers)}
                </div>
            </div>
        )
        // } else {
        //     return (
        //         <div className="target-selects">
        //             <div className="target-selects-button"
        //                 onClick={(_) => {setIsShowTargetSelects(!isShowTargetSelects)}}>
        //                 <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
        //                 <span>閲覧期限と通知先を設定</span>
        //             </div>
        //         </div>
        //     )
        // }
    }

    return (
        <div>
            <h2>共有したい情報を入力してください</h2>
            <form className="url-input-card">
                <div className="target-description">
                    <span className="target-description-subtitle">共有したい URL とタイトルを入力してください</span>
                </div>
                <input className="textarea" type="url" placeholder="https://example.io/123456" required
                    pattern="https?://[\w!\?/\+\-_~=;\.,\*&@#\$%\(\)'\[\]]+"
                    onChange={(e)=>{
                        setShareURL(e.target.value)
                        setValidURL(e.target.validity.valid)
                    }} />
                {renderWaringMessage()}
                <input className="textarea" type="textarea" placeholder="URL のタイトルを入力" required
                    onChange={(e)=>{setShareURLTitle(e.target.value)}} />
                {renderTargetSelects()}
                {renderButton()}
            </form>
        </div>
    );
};

export default InputURL;