import React, { useEffect } from "react";
import { withRouter } from "react-router";
import Header from "./Header";
import Footer from "./Footer";
import LoginCard from "./Login/LoginCard";
import CookieConsentBanner from "./Cookie/CookieConsentBanner";

const org_name = window.location.host.split('.')[0];

const Login = (props) => {
    useEffect(() => {
        document.title = `ログイン | ${org_name} | Oudan Sharing`;
    });

    return (
        <div className="main">
            <Header />
            <div className="contents">
                <LoginCard />
            </div>
            <CookieConsentBanner />
            <Footer />
        </div>
    )
}

export default withRouter(Login);